<template>
  <b-container fluid class="preloader m-0">
    <b-img
      width="auto"
      src="../assets/wottlogo.svg"
      alt="Loading WOTT"
      class="user-select-none"
    />
    <h2 v-if='!error'>Loading...</h2>
    <div v-if="error">
      <h3 class="mb-3 text-quaternary">
        Something went wrong. Reload or try again later.
      </h3>
    </div>
  </b-container>
</template>

<script>
export default {
  data: () => {
    return {
      error: false,
      timeout: null,
    };
  },
  created() {
    // let self = this;

    let p1 = this.get_user_and_subscribe()

    let p2 = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 300);
    });

    let p3 = this.$store.dispatch("channels/fetch_channels");

    Promise.all([p1, p2, p3])
      .then(() => {
        this.$store.dispatch('channels/set_initial_channel')
        this.$store.commit("set_init", true);
      })
      .catch((e) => {
        this.error = true;
        throw e;
      })
      .finally(() => {
        clearTimeout(this.timeout);
      });
  },
  mounted() {
    this.timeout = setTimeout(this.set_error, 10000);
  },
  methods: {
    async get_user_and_subscribe() {
      await this.$store.dispatch('user/get_user')
      if (this.$store.getters['user/is_authenticated']) {
        await this.$store.dispatch('subscribe', {item_type: 'user'})
      }
    },
    set_error: function () {
      this.error = true;
      throw "ui init timeout exceeded";
    },
  },
};
</script>

<style scoped lang="scss">
.preloader {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  font-family: "Montserrat";
  color: var(--yellow);

  img {
    max-width: 150px;
  }
  background:linear-gradient(to bottom, black, #3a3a3a 50%, black);
}
</style>
