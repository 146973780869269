<template>
  <div>
    <b-link class="mr-2" id='notification-popover-target'>
      <b-iconstack variant="white">
        <b-icon-bell-fill stacked scale="1.0" />
          <b-icon-dot
            v-if='$store.getters["notifications/has_unseen"]'
            stacked
            scale="2"
            shift-v="6"
            shift-h="4"
            variant="danger"
            />
      </b-iconstack>
    </b-link>
    <b-popover triggers='click blur' placement='bottom' target='notification-popover-target'
               variant='secondary' class='p-0'>
      <div v-if='$store.state.notifications.notifications && $store.state.notifications.notifications.length' class='notifications-list'>
        <div v-for='notification in $store.state.notifications.notifications'
             :key='notification.id' class='py-3 px-4'
             @click='notification_clicked(notification)'>
          <div class='d-flex justify-content-end'>
            <small>
              {{$moment(notification.timestamp).fromNow()}}
            </small>
          </div>
          <div :class='{"font-weight-bolder": !notification.seen}'>
            {{$utils.to_title_case(notification.name)}}
          </div>
        </div>
      </div>
      <div v-else class='p-4'>
        Notifications will be displayed here.
      </div>
    </b-popover>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  methods: {
    async notification_clicked(notification) {
      if (!notification.seen) {
        await axios.put('/api/notifications', {notification_id: notification.id, seen: true})
        notification.seen = true
      }
    }
  }
}
</script>

<style scoped>
.notifications-list > * {
  border-bottom: 1px solid black;
}
.notifications-list > *:last-child {
  border-bottom: none;
}
</style>
