import { render, staticRenderFns } from "./TextBlock.vue?vue&type=template&id=16b97efc&scoped=true&"
var script = {}
import style0 from "./TextBlock.vue?vue&type=style&index=0&id=16b97efc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b97efc",
  null
  
)

export default component.exports