<template>
  <div class='auto-grid-200'>
    <div v-for='category in categories' :key='category.id'
      class='p-2 border'>
      <small>{{category.id }}</small>
      <div>
        {{ category.title }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      categories: null
    }
  },
  async mounted() {
    this.categories = (await axios.get('/api/categories')).data
  }
}
</script>
