<template>
  <div>
    <b-form inline class="mb-sm-3" @submit.prevent="add_user_clicked">
      <label class="mr-sm-2" for="inline-form-input-name">Email</label>
      <b-input
        id="inline-form-input-name"
        class="mb-2 mr-sm-2 mb-sm-0"
        v-model="add_user.email"
        required
      ></b-input>
      <label class="mr-sm-2" for="inline-form-input-password">Password</label>
      <b-input
        id="inline-form-input-password"
        class="mb-2 mr-sm-2 mb-sm-0"
        type="password"
        v-model="add_user.password"
        required
      ></b-input>
      <b-button class="my-sm-2" type="submit" variant="warning"
        >Add User</b-button
      >
    </b-form>
    <b-table v-if="users" :items="users" :fields="fields">
      <template v-slot:cell(roles)="data">
        {{ data.item.roles.join(" ") }}
      </template>
      <template v-slot:cell(actions)="data">
        <!--<b-link @click="message_user_clicked(data.item.id)">Message</b-link>-->
        <b-link @click="edit_user_clicked(data.item.id)">Edit</b-link>
        <b-link @click="delete_user_clicked(data.item)">Delete</b-link>
      </template>
    </b-table>
    <div v-else>No users so far.</div>

    <b-modal
      v-model="delete_user.modal"
      @ok="do_delete_user"
      title="Delete user?"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      body-bg-variant="secondary"
    >
      <p class="my-4">Deleting user '{{ delete_user.user.email }}'</p>
    </b-modal>

    <b-modal v-model="edit_user.modal">
      <admin-edit-user :id="edit_user.user.id" />
    </b-modal>
  </div>
</template>

<script>
import AdminEditUser from "@/components/AdminEditUser.vue";

export default {
  components: { AdminEditUser },
  data: () => {
    return {
      edit_user: { modal: false, user: {} },
      delete_user: { modal: false, user: {} },
      add_user: {
        email: "",
        password: "",
      },
      users: [],
      fields: ["id", "email", "roles", "actions", "ratings"],
    };
  },
  created: function () {
    this.fetch_users();
  },
  methods: {
    fetch_users: function () {
      this.$http
        .get("/api/users")
        .then((response) => {
          this.users = response.data;
        })
        .catch(this.$utils.maybe_ignore_error);
    },
    add_user_clicked: function () {
      this.$http
        .post("/api/users", this.add_user)
        .then(() => {
          this.fetch_users();
        })
        .catch(this.$utils.maybe_ignore_error);
    },
    message_user_clicked: function () {
      this.$store.commit("push_error", "Not implemented");
    },
    edit_user_clicked: function (user_id) {
      this.edit_user.user.id = user_id;
      this.edit_user.modal = true;
    },
    delete_user_clicked: function (user) {
      this.delete_user.user = user;
      this.delete_user.modal = true;
    },
    do_delete_user: function () {
      this.$http
        .post("/api/delete-user", { id: this.delete_user.user.id })
        .then(() => {
          this.fetch_users();
          this.delete_user.user = {};
        })
        .catch(this.$utils.maybe_ignore_error);
    },
  },
};
</script>
