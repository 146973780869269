<template>
  <div>
    <div v-if="$store.state.player.timeslot">
      <div class='d-flex' style='flex-basis: 50%'>
        <div>
          <template v-if="!is_winner && auction_is_active">
            <p>Bid to win the next available Timeslot!</p>
            <p>
              Current bid:
              <rating :value="$store.state.player.timeslot.max_bid || 0" />
            </p>
            <div class="bid-input-wrapper">
              <b-input
                v-model="$store.state.player.bid_amount"
                type="number"
                placeholder="Place your bid"
                :min="$store.state.player.timeslot.max_bid + 1 || 1"
                :disabled="!auction_is_active"
                @keydown.enter='bid_clicked'
                ></b-input>
              <b-button
                @click="bid_clicked"
                :disabled="!auction_is_active"
                class="yellow-button"
                >Bid!</b-button
              >
            </div>
            <div class="statisctics">
              <span
                ><img src="@/assets/back-in-time.svg" alt="" />Time remaining
                <b>{{ time_remaining_auction }}</b></span
              >
              <span
                ><img src="@/assets/arrow-up-square.svg" alt="" />Top bid
                <b
                  ><img
                     src="@/assets/ratings-symbol.svg"
                     class="rating-symbol"
                     alt=""
                     />{{ $store.state.player.timeslot.max_bid || 0 }} ratings</b
                   ></span
                 >
                 <span
                   ><img src="@/assets/yellow-star.svg" alt="" />You have
                   <b
                     ><img
                        src="@/assets/ratings-symbol.svg"
                        class="rating-symbol"
                        alt=""
                        />{{ user.ratings }} ratings</b
                      ></span
                    >
                    <a href="#" @click.prevent="$emit('openBuyRatingsModal')"
                       >Buy ratings</a
                     >
            </div>
          </template>

          <div v-if='is_winner'>
            <h2>You won!</h2>
            <p>Timeslot ends in: {{ time_remaining_timeslot }}</p>
            <b-input
              class="mb-2"
              @input="video_set = false"
              placeholder="Enter youtube link"
              v-model="$store.state.player.youtube"
              />
              <b-button @click="set_video" variant="primary">Set video</b-button>
              <div v-if="video_set" class="mt-2">Video set!</div>
              <div v-if="setting_video" class="mt-2">Setting video...</div>
          </div>

          <div v-if="!is_winner && !auction_is_active" class="mt-3">
            Waiting for next timeslot: {{ time_remaining_timeslot }}
          </div>
        </div>
        <div class="live-rating-component__info__bidder ml-auto">
          <h3 class='mb-3'>Top Bidders</h3>
          <div class="list-of-bidders">
            <div
              v-for="({ name, amount, picture }, indx) in timeslot.top_bidders"
              :key="`bidder_${indx}`"
              class="bidder"
              >
              <b-avatar variant="" class='border border-primary' :src="picture" alt="" />
              <div class="bidder-info">
                <span class="name">{{ name }}</span>
                <span class="bid">Bid +{{ amount }} ratings</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>No auction at this time.</div>
    <!-- <div class="ml-2" style="flex: 1 0 33%; overflow: hidden">
      <b-img src="../assets/Anonymous_Sandglass.png" width="150px" />
      </div> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Rating from "@/components/Rating.vue";

export default {
  components: { Rating },
  data: () => {
    return {
      setting_video: false,
      video_set: false,
    };
  },
  created() {
    this.update_interval = setInterval(this.update, 1000);
  },
  beforeDestroy() {
    clearInterval(this.update_interval);
  },
  computed: {
    ...mapGetters("player", ["auction_is_active", "is_winner", "timeslot"]),
    ...mapState("player", [
      "time_remaining_auction",
      "time_remaining_timeslot",
    ]),
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    update() {
      this.$store.dispatch("player/update");
    },
    bid_clicked() {
      this.$store.dispatch("player/bid");
    },
    set_video() {
      this.setting_video = true;
      this.video_set = false;
      this.$store
        .dispatch("player/set_video")
        .then(() => {
          this.video_set = true;
        })
        .finally(() => {
          this.setting_video = false;
        });
    },
  },
  watch: {
    is_winner() {
      this.video_set = false;
      this.$store.state.player.youtube = "";
    },
  },
};
</script>
<style scoped lang="scss">
.bid-input-wrapper {
  display: flex;
  align-items: center;
  padding: 8px 7px;
  background: rgba(82, 82, 82, 0.92);
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 22px;

  input {
    font-size: 14px;
    padding: 0 7px;
    opacity: 0.7;
    background: rgba(82, 82, 82, 0.92);
    border: none;

    &:focus,
    &:focus-visible {
      border: none;
    }
  }

  .yellow-button {
    padding: 3px 15px;
    font-size: 16px;
    line-height: 31px;
  }
}

.statisctics {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;

  font-size: 16px;
  line-height: 31px;
  color: #e6e6e6;

  span {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    b {
      margin-left: 10px;
    }

    .rating-symbol {
      width: 10px;
      margin-right: 3px;
    }
  }

  img {
    width: 19px;
    margin-right: 8px;
  }

  a {
    font-weight: 600;
    margin-left: 27px;
    text-decoration: underline;
  }
}
</style>

<style scoped lang="scss">
.live-rating-component {
  background: linear-gradient(to left, #323232 43%, var(--secondary) 0%);

  @media screen and (max-width: 1024px) {
    background: var(--secondary);
  }

  .wott-container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    padding: 24px 50px 60px 0;
    gap: 80px;

    @media screen and (max-width: 1400px) {
      flex-direction: column;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: row;
      padding: 20px 0;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__auction {
      h2 {
        margin-bottom: 50px;
      }
    }

    &__bidder {
      min-width: 210px;

      h2 {
        margin-bottom: 50px;
      }

      .list-of-bidders {
        display: flex;
        flex-direction: column;

        .bidder {
          display: flex;
          gap: 13px;
          margin-bottom: 26px;

          &:first-child {
            img {
              border: 1px solid var(--warning);
            }
          }

          &:not(:first-child) {
            opacity: 0.55;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: 48px;
            border-radius: 50%;
          }

          .bidder-info {
            display: flex;
            flex-direction: column;

            .name {
              font-size: 16px;
              line-height: 24px;
              font-weight: 600;
            }

            .bid {
              font-size: 16px;
              line-height: 18px;
              font-weight: 500;
              color: var(--warning);
            }
          }
        }
      }
    }
  }

  &__chart {
    padding: 40px 0 40px 70px;

    @media screen and (max-width: 1024px) {
      padding: 20px 0;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      align-items: center;
    }

    &__content {
      overflow: hidden;
    }
  }
}
</style>
