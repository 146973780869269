<template>
  <div class="live-rating-component">
    <div class="wott-container">
      <div class="live-rating-component__info">
        <div class="live-rating-component__info__auction">
          <h2>Time Slot Auction</h2>
          <wott-auction
            v-on:openBuyRatingsModal="$emit('openBuyRatingsModal')"
          ></wott-auction>
        </div>
      </div>
      <div class="live-rating-component__chart">
        <div class="live-rating-component__chart__header">
          <h2>Live statistics</h2>
          <b-dropdown size="sm" split text="This week" class="m-2">
            <b-dropdown-item-button>This week</b-dropdown-item-button>
            <b-dropdown-item-button>This month</b-dropdown-item-button>
            <b-dropdown-item-button>Today</b-dropdown-item-button>
          </b-dropdown>
        </div>
        <div class="live-rating-component__chart__content">
          <live-rating-chart v-if="!loadingChart" :incoming-data="chardData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WottAuction from "./WottAuction.vue";
import LiveRatingChart from "./LiveRatingChart.vue";

export default {
  components: {
    WottAuction,
    LiveRatingChart,
  },
  data() {
    return {
      chardData: [],
      loadingChart: true,
    };
  },
  created() {
    this.fetch_viewer_stats();
  },
  methods: {
    fetch_viewer_stats() {
      this.$http
        .get("/api/viewer-stats")
        .then((response) => {
          this.chardData = response.data.map((v) => {
            return { x: v.hour, y: v.viewers };
          });
        })
        .finally(() => {
          this.loadingChart = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.live-rating-component {
  background: linear-gradient(to left, #323232 43%, var(--secondary) 0%);

  @media screen and (max-width: 1024px) {
    background: var(--secondary);
  }

  .wott-container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__info {
    justify-content: space-between;
    padding: 24px 50px 60px 0;
    gap: 80px;

    @media screen and (max-width: 1400px) {
      flex-direction: column;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: row;
      padding: 20px 0;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__auction {
      h2 {
        margin-bottom: 50px;
      }
    }

    &__bidder {
      min-width: 210px;

      h2 {
        margin-bottom: 50px;
      }

      .list-of-bidders {
        display: flex;
        flex-direction: column;

        .bidder {
          display: flex;
          gap: 13px;
          margin-bottom: 26px;

          &:first-child {
            img {
              border: 1px solid var(--warning);
            }
          }

          &:not(:first-child) {
            opacity: 0.55;
          }

          img {
            max-width: 48px;
            border-radius: 50%;
          }

          .bidder-info {
            display: flex;
            flex-direction: column;

            .name {
              font-size: 16px;
              line-height: 24px;
              font-weight: 600;
            }

            .bid {
              font-size: 16px;
              line-height: 18px;
              font-weight: 500;
              color: var(--warning);
            }
          }
        }
      }
    }
  }

  &__chart {
    padding: 40px 0 40px 70px;

    @media screen and (max-width: 1024px) {
      padding: 20px 0;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      align-items: center;
    }

    &__content {
      overflow: hidden;
    }
  }
}
</style>
