<template>
  <div class="programming-input">
    <b-input-group-prepend v-if="is_on_air" class=''>
      <b-input-group-text class="text-danger font-weight-bolder">
        ON AIR
      </b-input-group-text>
    </b-input-group-prepend>
    <input
      class='ml-2'
      :placeholder="placeholder"
      v-model="video_url"
      @input="
        link_submitted = false;
        link_invalid = false;
      "
      @keydown.enter="submit_link"
      :disabled="!user.authenticated"
    />

    <template v-if="link_submitted">
      <b-input-group-text>
        <b-icon-check color="green" />
      </b-input-group-text>
    </template>
    <template v-else-if="link_invalid">
      <b-input-group-text>
        <b-icon-x color="red" />
      </b-input-group-text>
    </template>
    <template v-else-if="link_loading">
      <b-input-group-text>
        <b-spinner small variant="primary" />
      </b-input-group-text>
    </template>
    <template v-else>
      <b-button variant="primary" class="submit" @click="submit_link"
        :disabled='!user.authenticated'>
        <b-icon-arrow-right class="" font-scale="1.0" />
      </b-button>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      video_url: "",
      link_loading: false,
      link_submitted: false,
      link_invalid: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    is_on_air() {
      return this.playback && this.user.id && this.user.id === this.playback.user_video.user_id;
    },
    playback() {
      return this.$store.getters["player/playback"];
    },
    placeholder() {
      if (this.user.authenticated) {
        return "Paste youtube video";
      }
      return "Sign up to submit a video link here";
    },
  },
  methods: {
    submit_link() {
      if (!this.video_url) {
        return;
      }

      let id = this.$utils.to_youtube_id(this.video_url);
      if (id === null) {
        this.link_invalid = true;
        return;
      }
      this.link_loading = true;

      this.$http
        .post("/api/add-video", {
          youtube_id: id,
          channel_id: this.$store.state.channels.current_channel.id,
        })
        .then(() => {
          this.link_submitted = true;
        })
        .finally(() => {
          this.link_loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.programming-input {
  display: flex;
  justify-content: space-between;
  width: 100%;

  input,
  input:focus {
    outline: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    background: transparent;
    border: none;
    color: #ffffff;
    width: 100%;
  }

  input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  .submit {
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
  }
}
</style>
