import { render, staticRenderFns } from "./WottAuction.vue?vue&type=template&id=1c71d395&scoped=true&"
import script from "./WottAuction.vue?vue&type=script&lang=js&"
export * from "./WottAuction.vue?vue&type=script&lang=js&"
import style0 from "./WottAuction.vue?vue&type=style&index=0&id=1c71d395&scoped=true&lang=scss&"
import style1 from "./WottAuction.vue?vue&type=style&index=1&id=1c71d395&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c71d395",
  null
  
)

export default component.exports