<template>
  <b-modal v-model="active" hide-header hide-footer size="md">
    <p>
      Once you have achieved the rank of Network VP, you will have the ability to create and run your own
      channel! </br>You can customize the rules for your channel and ban users.
    </p>
      <div class="buttons-group">
        <b-button
            class="yellow-button float-right"
            @click.prevent="$emit('done')"
        >
          Ok
        </b-button>
      </div>
  </b-modal>
</template>
<script>
export default {
  name: "AboutUserCreateChannelModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style scoped lang="scss"></style>
