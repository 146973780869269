<template>
  <b-modal
    v-model="active"
    hide-footer
    scrollable
    :dialog-class="['full-screen']"
  >
    <template #modal-header>
      <h2>Admin Panel</h2>
      <div class="buttons-group">
        <a href="#" @click.prevent="$emit('done')" class="close-button">
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>
    <b-tabs small content-class='p-2' class="" pills @input="tabChanged" lazy
      nav-class='border-bottom'>
      <b-tab title="Users">
        <admin-users />
      </b-tab>
      <b-tab title="User Videos">
        <admin-videos />
      </b-tab>
      <b-tab title="Playbacks">
        <admin-playbacks />
      </b-tab>
      <b-tab title="Timeslots">
        <admin-timeslots />
      </b-tab>
      <b-tab title="Channels">
        <admin-channels />
      </b-tab>
      <b-tab title="Websocket">
        <admin-websocket />
      </b-tab>
      <b-tab title="Bans">
        <admin-bans/>
      </b-tab>
      <b-tab title='Categories'>
        <admin-categories/>
      </b-tab>
      <b-tab title="Popular videos">
        <admin-popular-videos/>
      </b-tab>
      <b-tab title="Related videos">
        <admin-related-videos/>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import AdminUsers from "@/components/AdminUsers.vue";
import AdminWebsocket from "@/components/AdminWebsocket.vue";
import AdminVideos from "@/components/AdminVideos.vue";
import AdminPlaybacks from "@/components/AdminPlaybacks.vue";
import AdminTimeslots from "@/components/AdminTimeslots.vue";
import AdminChannels from "@/components/AdminChannels.vue";
import AdminBans from "@/components/AdminBans.vue";
import AdminPopularVideos from '@/components/AdminPopularVideos.vue'
import AdminRelatedVideos from '@/components/AdminRelatedVideos.vue'
import AdminCategories from '@/components/AdminCategories.vue'

export default {
  components: {
    AdminUsers,
    AdminWebsocket,
    AdminVideos,
    AdminPlaybacks,
    AdminTimeslots,
    AdminChannels,
    AdminBans,
    AdminPopularVideos,
    AdminRelatedVideos,
    AdminCategories
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    tabChanged(currentTab) {
    },
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.full-screen {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}
</style>
