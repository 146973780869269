<template>
  <span style="">
    <span class="text-danger">R</span
    ><span class="" :style="{ color: '#00aa00' }">{{ value }}</span>
  </span>
</template>

<script>
export default {
  props: ["value"],
};
</script>
