<template>
  <div>
    <b-button @click='fetch_videos'>Reload</b-button>
    <b-button @click='delete_all'>Delete all</b-button>
    <div v-if='videos' v-for='video in videos' :key='video.id'
         class='p-2 border-bottom'
      >
      <div class='d-flex justify-content-between'>
        <div>
          <small>{{video.id}}</small>
          <small>{{video.category}}</small>
        </div>
        <div>
          <small>Updated at: {{video.updated_at}}</small>
          <small>Pos: {{video.pos}}</small>
        </div>
      </div>
      <div>
        Video id: {{video.video_id}}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      videos: null
    }
  },
  async mounted() {
    await this.fetch_videos()
  },
  methods: {
    async fetch_videos() {
      this.videos = (await axios.get('/api/popular-videos')).data
    },
    async delete_all() {
      await axios.delete('/api/popular-videos')
      this.videos = []
    }
  }
}
</script>
