<template>
  <b-modal v-model="active" hide-footer size="lg">
    <template #modal-header>
      <h2>Buy Ratings</h2>

      <div class="buttons-group">
        <a href="#" @click.prevent="$emit('done')" class="close-button">
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>
    <b-form class="edit-profile">
      <p>
        In the future, users can buy ratings to support the site and keep
        Tellysim ad-free.
      </p>
    </b-form>
  </b-modal>
</template>
<script>
export default {
  name: "BuyRatingsModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
