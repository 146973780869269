<template>
  <div>
    <b-table :items="playbacks" :fields="fields">
      <template #cell(date_started)="data">
        {{ $moment(data.item.date_started).format("MM-DD HH:mm") }}
      </template>
      <template #cell(date_ended)="data">
        {{ $moment(data.item.date_ended).format("MM-DD HH:mm") }}
      </template>
      <template #cell(votes)="data">
        <span class="text-success">
          {{ data.item.votes.filter((x) => x.positive).length }}
        </span>
        <span class="text-danger">
          {{ data.item.votes.filter((x) => !x.positive).length }}
        </span>
      </template>
      <template #cell(user_video)="data">
        {{ data.item.user_video.id }}
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      fields: [
        "id",
        { key: "date_started", label: "Started" },
        { key: "date_ended", label: "Ended" },
        "votes",
        "user_video",
      ],
    };
  },
  computed: {
    playbacks() {
      return this.$store.state.data.playbacks;
    },
  },
  async created() {
    this.$store.state.data.playbacks = (await axios.get('/api/playbacks')).data
  },
  methods: {},
};
</script>
