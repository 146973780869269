<template>
  <div :class="['wott-chat', chatOpened ? 'chat-opened' : '']">
    <div :class="['wott-chat__top-panel', user.authenticated ? '' : 'guest']">
      <span
        class="wott-chat__panel-switcher"
        v-if="chatOpened && user.authenticated"
        @click.prevent="chatPanel = !chatPanel"
      >
        {{ chatPanel ? "Show" : "Hide" }} viewers
      </span>
      <span
        class="wott-chat__visibility-switcher"
        @click.prevent="toggleChatOpen"
      >
        {{ chatOpened ? "Hide" : "Show" }} Chat
      </span>
    </div>

    <div v-show="chatOpened && chatPanel" class="wott-chat__messages">
      <div class="wott-chat__statistics">
        <span
          ><img src="@/assets/eye.svg" alt="" />
          <b>{{ active_user_ids.length }}</b> Viewer<template
            v-if="active_user_ids.length > 1"
            >s</template
          ></span
        >
        <span>
          <img class="mr-1" src="@/assets/arrow-up-square.svg" alt="" />
          Top bid<b> {{channel_max_bid}} ratings</b>
        </span>
      </div>

      <div class="wott-chat__messages-list" ref="messages">
        <div
          v-for="message in reverseArray(messages)"
          :key="message.id"
          class="my-3 d-flex align-items-center"
        >
          <b-avatar
            :src="message.author_picture"
            size="40"
            class="mr-2"
          ></b-avatar>
          <div>
            <div
              :style="{ color: get_color(message.author_rank) }"
              class="message-author-name"
            >
              {{ message.author_name }}
            </div>
            <div class="message-body">
              {{ message.body }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="!messages.length">No messages yet</div>
    </div>
    <b-form
      v-show="chatOpened && chatPanel"
      @submit.prevent="send_clicked"
      class="wott-chat__form"
    >
      <b-input
        v-model="message"
        :placeholder="
          user.authenticated ? 'Type your message...' : 'Sign up to enter chat'
        "
        class="w-100 mr-2"
        :disabled="!user.authenticated"
      />
      <b-button type="submit" :disabled="!user.authenticated">
        <img src="@/assets/arrow-up-square.svg" alt="" />
      </b-button>
    </b-form>
    <div v-show="!chatPanel" class="wott-chat__users-list">
      <div class="wott-chat__messages-list" ref="messages">
        <div
          v-for="viewer in getCurrentChannelViewers"
          :key="viewer.id"
          class="my-3 d-flex align-items-center"
        >
          <b-avatar
            src="viewer.author_picture"
            size="40"
            :class="{'mr-2': true, 'active-viewer-border': active_user_ids.indexOf(viewer.id) !== -1}"
          ></b-avatar>
          <div class="user-details">
            <div>
              {{ viewer.name }}
            </div>
            <b-link
              v-if="
                userIsAdminOrOwner && getCurrentChannel.user_id !== viewer.id
              "
              @click.prevent="banUser(viewer.id)"
            >
              <img src="@/assets/red-stop.svg" alt="" />
            </b-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "axios";

let reverseArray = (arr) => {
  //Temp array
  let temp = [];

  for (let i = 0; i < arr.length; i++) {
    //Copy all the values in reverse order
    temp[i] = arr[arr.length - i - 1];
  }

  return temp;
};

export default {
  data: () => {
    return {
      message: "",
      first: true,
      chatPanel: true,
    };
  },
  async mounted() {
  },
  computed: {
    ...mapState("chat", ["messages"]),
    ...mapState("channels", ["active_user_ids"]),
    ...mapGetters("user", ["user"]),
    ...mapGetters("chat", ["chatOpened"]),
    ...mapGetters("channels", [
      "getCurrentChannel",
      "getCurrentChannelViewers",
    ]),
    channel_max_bid() {
      if (this.$store.state.channels.channel_max_bid)
        return this.$store.state.channels.channel_max_bid.amount
      return 0
    },
    userIsAdminOrOwner() {
      return (
        this.user.roles.includes("admin") ||
        this.user.id === this.getCurrentChannel.user_id
      );
    },
  },
  methods: {
    ...mapActions({
      toggleChatOpen: "chat/toggleChatOpen",
    }),
    get_color(rank) {
      return this.$utils.get_rank_color(rank);
    },
    send_clicked() {
      this.$store.dispatch("chat/send_message", this.message).then(() => {
        this.message = "";
      });
    },
    reverseArray,
    banUser(userId) {
      axios
        .post(`/api/bans`, {
          user_id: userId,
          channel_id: this.getCurrentChannel.id,
        })
        .then(() => {
          this.$store.commit("push_alert", {
            show: true,
            type: "success",
            message: "User banned",
          });
        });
    },
  },
  watch: {
    messages() {
      document.querySelectorAll('.wott-chat__messages-list').forEach(e => {
        let should_scroll = true
          //this.first || e.scrollTop + e.clientHeight === e.scrollHeight;
        this.$nextTick(() => {
          if (should_scroll) e.scrollTop = e.scrollHeight
        });
      })
      this.first = false;
    },
  },
};
</script>

<style lang="scss">
.wott-chat {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, 80%, #181818 100%);

  &.chat-opened {
    .wott-chat {
      &__top-panel {
        margin: 0 22px;

        &.guest {
          justify-content: flex-end;
        }
      }

      &__messages,
      &__form {
        display: flex;
      }

      &__visibility-switcher {
        writing-mode: horizontal-tb;
        justify-content: end;
      }
    }
  }

  &__top-panel {
    display: flex;
    justify-content: space-between;
  }

  &__panel-switcher {
    cursor: pointer;
  }

  &__visibility-switcher {
    writing-mode: vertical-lr;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1240px) {
      writing-mode: horizontal-tb;
      justify-content: end;
    }
  }

  &__messages,
  &__users-list {
    margin: 0 22px;
    flex-direction: column;
    height: 100%;
    gap: 10px;
  }

  &__messages {
    display: none;

    &-list {
      overflow-y: auto;
      max-height: 475px;

      @media screen and (max-width: 768px) {
        max-height: calc(100vh - 150px);
      }
    }
  }

  &__statistics {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    img {
      height: 24px;
      width: 24px;
    }
  }

  &__form {
    display: none;
    align-items: center;
    margin: 22px;
    padding: 11px 16px;
    background: rgba(82, 82, 82, 0.26);

    input,
    input:focus,
    input:disabled {
      margin: 0;
      padding: 0;
      font-size: 14px;
      background: transparent;
    }

    button {
      padding: 0;
      border: none;
      width: 29px;
      height: 29px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.message-author-name {
  font-size: 12px;
}

.message-body {
  color: #fff;
  font-size: 14px;
}

.user-details {
  display: flex;
  align-items: center;
  gap: 7px;
}

.active-viewer-border { 
  border: 1px solid #5fd46f
}
</style>
