<template>
  <b-modal v-model="show_modal" :hide-footer="true" size="lg">
    <template #modal-header>
      <h2>Sign Up</h2>
      <b-button @click="submit_triggered" variant="primary" :disabled="loading">
        <template v-if="loading">
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </template>
        <template v-else> Submit </template>
      </b-button>
    </template>
    <b-form @submit.prevent="submit_triggered">
      <b-form-group
        id="input-group-name"
        label="Name"
        label-for="input-name"
        class=""
      >
        <b-form-input id="input-name" v-model="user.name" autofocus />
      </b-form-group>
      <b-form-group
        id="input-group-email"
        label="Email"
        label-for="input-email"
      >
        <b-form-input id="input-email" v-model="user.email" required />
      </b-form-group>
      <b-form-group
        id="input-group-password"
        label="Password"
        label-for="input-password"
      >
        <b-form-input
          type="password"
          id="input-password"
          v-model="user.password"
          required
        />
      </b-form-group>
      <b-button type="submit" style="display: none">Submit</b-button>
    </b-form>

    <div>
      <b-link @click="$emit('signin')"
        >Already have an account? Sign in!</b-link
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["value"],
  data: () => {
    return {
      user: {
        name: "",
        email: "",
        password: "",
      },
      loading: false,
    };
  },
  computed: {
    show_modal: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        return this.value;
      },
    },
  },
  created() {},
  methods: {
    submit_triggered: function () {
      this.loading = true;
      this.$store
        .dispatch("user/signup", this.user)
        .then(() => {
          this.$emit("done");
        })
        .catch(this.$utils.maybe_ignore_error)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
