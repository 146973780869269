<template>
  <div class="wott-container">
    <div class="leader-board-component">
      <template v-if="loading && !leaderboard.length">
        <b-spinner class="align-middle" />
      </template>

      <template v-else>
        <div class="leader-board-component__header">
          <h2>Leaderboard</h2>

          <!--
          <b-dropdown size="md" split text="This week" class="m-2">
            <b-dropdown-item-button>This week</b-dropdown-item-button>
            <b-dropdown-item-button>This month</b-dropdown-item-button>
            <b-dropdown-item-button>Today</b-dropdown-item-button>
          </b-dropdown>
          -->
        </div>

        <div class="leader-board-component__body">
          <div class="leader-board-component__body__content">
            <b-table
              striped
              :items="leaderboard"
              :fields="fields"
              :noCollapse="true"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #cell(index)="data">
                <b-button
                  ><span class="d-flex">#{{ (currentPage - 1) * perPage + data.index + 1 }}</span></b-button
                >
              </template>
              <template #cell(name)="data">
                <span class="flex-cell">
                  <b-avatar :src="data.item.picture" class="mr-3"></b-avatar>
                  <span class="name-font">
                    {{ data.item.name }}
                  </span>
                </span>
              </template>
              <template #cell(location)="data">
                <span>{{ data.item.location }}</span>
              </template>
              <template #cell(ratings)="data">
                <span>{{ data.item.ratings }}</span>
              </template>
              <template #cell(plays)="data">
                <span>{{ data.item.plays }}</span>
              </template>
              <template #cell(most_popular_video)="data">
                <span class="flex-cell">
                  <b-img src="/link.png"></b-img>
                  <div class="customize-table-link">
                    <b-link
                      v-if="data.item.most_popular_video"
                      target="_blank"
                      :href="
                        youtube_link(data.item.most_popular_video.youtube_id)
                      "
                    >
                      {{
                        youtube_link(data.item.most_popular_video.youtube_id)
                      }}
                    </b-link>
                  </div>
                </span>
              </template>
            </b-table>
          </div>

          <div class="leader-board-component__body__pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="leader-board"
              class="no-background"
            ></b-pagination>
          </div>
        </div>

        <div class="leader-board-component__footer">
          <div class="leader-board-component__footer-title">
            <h2>Buy Ratings</h2>
          </div>
          <div class="leader-board-component__footer-content">
            <p>
              Support the site and increase your rank!
            </p>
            <a
              href="#"
              class="yellow-button"
              @click.prevent="$emit('openBuyRatingsModal')"
            >
              <span>Buy ratings</span>
              <b-img src="/arrow.png" class="ml-3"></b-img>
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      loading: true,
      perPage: 5,
      currentPage: 1,
      fields: [
        { key: "index", label: "Rank" },
        { key: "name", label: "Player" },
        { key: "location", label: "Location" },
        { key: "plays", label: " Tellysim Plays" },
        { key: "ratings" },
        { key: "most_popular_video", label: "Most Popular Video" },
      ],
    };
  },
  created() {
    this.fetch_leaderboard();
  },
  computed: {
    rows() {
      return this.leaderboard.length;
    },
    leaderboard: {
      get() {
        return this.$store.state.leaderboard
      },
      set(v) {
        this.$store.commit('set_leaderboard', v)
      }
    }
  },
  methods: {
    youtube_link(youtube_id) {
      return "https://youtube.com/watch?v=" + youtube_id;
    },
    fetch_leaderboard() {
      this.$http.get("/api/leaderboard").then((response) => {
        this.loading = false;
        this.leaderboard = response.data
      });
    },
  },
};
</script>

<style scoped lang="scss">
.leader-board-component {
  padding-top: 50px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    align-items: center;

    .btn {
      border-radius: 6px;
    }
  }

  &__body {
    &__content {
      overflow: auto;
      max-width: calc(100vw - 1px);

      .flex-cell {
        display: flex;
        gap: 10px;
      }

      .name-font {
        white-space: nowrap;
      }

      .customize-table-link {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 318px;
      }
    }
  }

  &__footer {
    padding-top: 20px;
    padding-bottom: 80px;

    @media screen and (max-width: 768px) {
      padding-bottom: 20px;
    }

    &-title {
      margin-bottom: 16px;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      p {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-weight: 400;

        font-size: 22px;
        line-height: 24px;

        @media screen and (max-width: 1320px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
