<template>
  <b-modal v-model="active" hide-footer size="lg">
    <template #modal-header>
      <h2><b-icon icon="pencil-fill"></b-icon> Edit profile</h2>

      <div class="buttons-group">
        <!-- <a href="#" @click.prevent="sendForm" class="yellow-button">
          Save Changes
          <b-icon icon="check"></b-icon>
        </a> -->
        <b-button
          :disabled="loading"
          class="yellow-button"
          @click.prevent="sendForm"
        >
          <template v-if="loading">
            Saving...
            <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
          </template>
          <template v-else>
            Save Changes <b-icon icon="check"></b-icon>
          </template>
        </b-button>
        <a
          href="#"
          @click.prevent="$emit('done'), (prevPicture = null)"
          class="close-button"
        >
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>
    <b-form class="edit-profile">
      <div class="profilew-cover">
        <label class="edit-profile__file">
          <b-form-file
            accept="image/jpeg, image/png, image/gif"
            @change="onPictureChange"
            @input="sendPicture"
            v-model="filePicture"
          ></b-form-file>
          <img v-if="!prevPicture" :src="form.picture" alt="" />
          <img v-if="prevPicture" :src="prevPicture" alt="" />
        </label>
      </div>

      <div>
        <b-form-group>
          <label for="name">Name</label>
          <b-form-input
            id="name"
            type="text"
            autocomplete="false"
            v-model="form.name"
          />
        </b-form-group>

        <b-form-group>
          <label for="location">Location</label>
          <b-form-input
            id="location"
            type="text"
            autocomplete="false"
            v-model="form.location"
          />
        </b-form-group>

        <b-form-group>
          <label for="about_me">Bio</label>
          <b-form-textarea
            id="about_me"
            v-model="form.about_me"
            rows="10"
            maxlength="200"
          />
          <span
            class="textarea-chars-count"
            :style="{ color: textareaCount === 200 ? 'red' : '' }"
            >{{ textareaCount }}/200</span
          >
        </b-form-group>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
export default {
  name: "EditProfileModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: "",
        location: "",
        about_me: "",
      },
      loading: false,
      filePicture: null,
      prevPicture: null,
    };
  },
  methods: {
    prepareData() {
      let currentUser = this.$store.getters["user/user"];

      this.form = Object.assign(
        {},
        {
          picture: currentUser.picture,
          name: currentUser.name,
          location: currentUser.location ? currentUser.location : "",
          about_me: currentUser.about_me ? currentUser.about_me : "",
        }
      );
    },
    sendForm() {
      this.loading = true;

      this.$http.post("/api/update-profile", this.form).then(() => {
        this.$store.dispatch("user/get_user").then(() => {
          this.loading = false;
          this.$store.commit("push_alert", {
            show: true,
            type: "success",
            message: "Changes saved",
          });

          this.$emit("done");
        });
      });
    },
    sendPicture() {
      let formDataPicture = new FormData();

      formDataPicture.append("file", this.filePicture);

      console.log(formDataPicture);

      this.$http
        .post("/api/update-profile-picture", formDataPicture, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          this.$store.dispatch("user/get_user").then(() => {
            this.loading = false;
            this.$store.commit("push_alert", {
              show: true,
              type: "success",
              message: "Changes saved",
            });

            this.$emit("done");
            this.prevPicture = null;
          });
        });
    },
    onPictureChange(e) {
      const file = e.target.files[0];
      this.prevPicture = URL.createObjectURL(file);
    },
  },

  computed: {
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    textareaCount() {
      return this.form.about_me.length;
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },

  watch: {
    active() {
      this.prepareData();
    },
  },
};
</script>
<style scoped lang="scss">
.yellow-button {
  svg {
    margin-left: 5px;
  }
}

.edit-profile {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 19px;

  .profilew-cover {
    position: relative;

    img {
      width: 100%;
      border-radius: 50%;
    }

    .edit-profile__file {
      position: relative;
      cursor: pointer;
      height: fit-content;

      &::before,
      &::after {
        opacity: 0;
        transition: 0.3s;
      }

      &:hover {
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 101%;
          height: 101%;
          background: #000000;
          border-radius: 50%;
          opacity: 0.73;
          z-index: 2;
          transition: 0.3s;
        }

        &::after {
          content: url("../../assets/icn-photo.svg");
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.62;
          z-index: 3;
          transition: 0.3s;
        }
      }

      .custom-file {
        position: absolute;
        border-radius: 50%;
        opacity: 0;
      }
    }
  }
}
</style>
