<template>
  <div :class="['video-block', chatOpened ? 'chat-opened' : '']">
    <wott-player></wott-player>
    <wott-chat></wott-chat>
  </div>
</template>
<script>
import WottPlayer from "@/components/VideoBlock/WottPlayer";
import WottChat from "@/components/VideoBlock/WottChat";

export default {
  components: {
    WottPlayer,
    WottChat,
  },
  computed: {
    chatOpened() {
      return this.$store.getters["chat/chatOpened"];
    },
  },
};
</script>
<style scoped lang="scss">
.video-block {
  display: grid;
  grid-template-columns: 1fr 30px;
  gap: 20px;
  margin-bottom: 44px;

  &.chat-opened {
    grid-template-columns: 1fr 334px;
  }

  @media screen and (max-width: 1240px) {
    display: flex;
    flex-direction: column;
  }
}
</style>
