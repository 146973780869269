<template>
  <div>
    <h1>Edit user: {{ id }}</h1>
    <div v-if="user">
      <b-form @submit.stop.prevent="update_triggered">
        <b-form-group
          id="input-group-email"
          label="Email"
          label-for="input-email"
        >
          <b-form-input id="input-email" v-model="user.email" autofocus />
        </b-form-group>
        <b-form-group id="input-group-name" label="Name" label-for="input-name">
          <b-form-input id="input-name" v-model="user.name" autofocus />
        </b-form-group>
        <b-form-group
          id="input-group-password"
          label="Password"
          label-for="input-password"
        >
          <b-form-input
            type="password"
            id="input-password"
            v-model="user.password"
          />
        </b-form-group>
        <b-form-group
          id="input-group-roles"
          label="Roles"
          label-for="input-roles"
          description="Space-separated list of strings"
        >
          <b-form-input id="input-roles" v-model="roles" />
        </b-form-group>
        <b-form-group label="Ratings">
          <b-form-input type="number" v-model="user.ratings" />
        </b-form-group>
        <b-button type="submit" variant="primary" :disabled="loading">
          <template v-if="loading">
            <b-spinner small type="grow"></b-spinner>
            Loading...
          </template>
          <template v-else> Submit </template>
        </b-button>
      </b-form>
      <div v-if="done">Changes saved.</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data: () => {
    return {
      user: null,
      loading: false,
      done: false,
    };
  },
  computed: {
    roles: {
      get: function () {
        return this.user.roles.join(" ");
      },
      set: function (val) {
        this.user.roles = val.split(" ");
      },
    },
  },
  created: function () {
    this.fetch_user();
  },
  methods: {
    fetch_user: function () {
      this.$http
        .get("/api/users", { params: { id: this.id } })
        .then((response) => {
          this.user = response.data[0];
          this.user.password = "";
        })
        .catch(this.$utils.maybe_ignore_error);
    },
    update_triggered: function () {
      this.user.ratings = parseInt(this.user.ratings);
      this.$http
        .post("/api/update-user", this.user)
        .then(() => {
          this.done = true;
        })
        .catch(this.$utils.maybe_ignore_error);
    },
  },
};
</script>
