<template>
  <div class="alerts-wrapper">
    <template v-for="(alert, indx) in $store.getters['alerts']">
      <b-alert
        :key="`alert_message_${indx}`"
        dismissible
        v-if="alert.error && alert.error.code === 5"
        variant="warning"
        v-model="alert.show"
        class="mb-1"
      >
        Confirm your email first.
        <resend-activation-link />
      </b-alert>
      <b-alert
        v-model="alert.show"
        v-else
        :variant="alert_variant(alert)"
        dismissible
        class="mb-1"
        :key="`alert_message_2_${indx}`"
      >
        {{ alert.message }}
        <span v-if="alert.count && alert.count > 1">({{ alert.count }})</span>
      </b-alert>
    </template>
  </div>
</template>

<script>
export default {
  methods: {
    alert_variant(alert) {
      if (alert.type === "error") return "danger";
      else if (alert.type === "success") return "success";
      return "info";
    },
  },
};
</script>

<style lang="scss" scoped>
.alerts-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  gap: 15px;
  z-index: 1041;

  > div {
    max-width: 450px;
    margin: auto;
  }
}
</style>
