<template>
  <b-modal v-model="active" hide-footer size="lg" v-if='channel'>
    <template #modal-header>
      <h2>About Channel</h2>

      <div class="buttons-group">
        <a href="#" @click.prevent="$emit('done')" class="close-button">
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>

    <p>
      Welcome to {{ channel.name }}.<br/><br/> Try to post videos related to our theme. Be nice in the chat and be generous
      with your upvotes!
    </p>

    <div class="grid-container">
      <p><span>Auction Length:</span> {{channel.auction_duration}} minutes</p>
      <p><span>Timeslot Length:</span> {{channel.timeslot_duration}} minutes</p>
      <p><span>Minimum Required Ratings:</span> {{channel.minimum_ratings}}</p>
      <p><span>Uncancellable Time :</span> {{channel.uncancellable_time_normal}} seconds</p>
      <p><span>Max Video Length:</span> {{channel.maximum_video_length}} minutes</p>
      <p><span>Uncancellable Timeslot:</span> {{channel.uncancellable_time_timeslot}} seconds</p>
      <p><span>Ratings Award Rate:</span> {{channel.ratings_multiplier}} per vote</p>
      <p><span>Theme:</span> {{category_name}}</p>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "AboutChannelModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    channel: {}
  },
  computed: {
    category_name() {
      if (this.channel && this.channel.category_ids.length) {
        return this.$store.getters['categories/categories'].find(x => x.id === this.channel.category_ids[0]).title
      }
      return ''
    },
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 10px;

  p {
    display: flex;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 31px;
    color: #E6E6E6;

    span {
      font-weight: 300;
      color: var(--yellow);
    }
  }

  @media screen and (max-width: 992px) {
    display: block;
  }
}
</style>
