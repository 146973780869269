<template>
  <div class='text-white'>
    <div>
      <b-button variant="primary" @click="create_channel">Create</b-button>
    </div>
    <div>
      <div class="my-2">
        Show archived:
        <b-checkbox
          @change="fetch_channels"
          v-model="show_archived"
          class="d-inline w-auto"
        />
      </div>
      <div>
        <div
          v-for="channel in channels"
          class="border my-2 p-2"
          :key="channel.id"
        >
          <table class='text-white'>
            <tr>
              <td>ID:</td>
              <td>{{ channel.id }}</td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>
                <b-input v-model="channel.name" class="d-inline w-auto" />
              </td>
            </tr>
            <tr>
              <td>Maximum video length:</td>
              <td>
                <b-input
                  v-model="channel.maximum_video_length"
                  class="d-inline w-auto"
                />
              </td>
            </tr>
            <tr>
              <td>Timeslot duration:</td>
              <td>
                <b-input
                  v-model="channel.timeslot_duration"
                  class="d-inline w-auto"
                />
              </td>
            </tr>
            <tr>
              <td>Auction duration:</td>
              <td>
                <b-input
                  v-model="channel.auction_duration"
                  class="d-inline w-auto"
                />
              </td>
            </tr>
            <tr>
              <td>Cancel pct:</td>
              <td>
                <b-input v-model="channel.cancel_pct" class="d-inline w-auto" />
              </td>
            </tr>
            <tr>
              <td>Uncancellable time normal:</td>
              <td>
                <b-input
                  v-model="channel.uncancellable_time_normal"
                  class="d-inline w-auto"
                />
              </td>
            </tr>
            <tr>
              <td>Uncancelable time timeslot:</td>
              <td>
                <b-input
                  v-model="channel.uncancellable_time_timeslot"
                  class="d-inline w-auto"
                />
              </td>
            </tr>
            <tr>
              <td>Minimum ratings:</td>
              <td>
                <b-input
                  v-model="channel.minimum_ratings"
                  class="d-inline w-auto"
                />
              </td>
            </tr>
            <tr>
              <td>Ratings multiplier:</td>
              <td>
                <b-input
                  v-model="channel.ratings_multiplier"
                  class="d-inline w-auto"
                />
              </td>
            </tr>
            <tr>
              <td>Private:</td>
              <td>
                <b-checkbox v-model="channel.private" />
              </td>
            </tr>
            <tr>
              <td>Archived:</td>
              <td>
                <b-checkbox v-model="channel.archived" />
              </td>
            </tr>
          </table>
          <div>
            <b-button variant="quaternary" @click="update_channel(channel)">
              Save
            </b-button>
            <b-link @click="delete_channel_clicked(channel)">Delete</b-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      channels: [],
      show_archived: false,
    };
  },
  async created() {
    await this.fetch_channels();
  },
  methods: {
    async delete_channel_clicked(channel) {
      let v = await this.$bvModal.msgBoxConfirm(
        'Delete channel "' + channel["name"] + '"'
      );
      if (v) {
        let url = "/api/channels";
        let data = { id: channel["id"] };
        let headers = { "Content-Type": "application/json" };
        await axios.request({ url, data, headers, method: "delete" });
        this.fetch_channels();
      }
    },
    async update_channel(channel) {
      await this.$http.put("/api/channels", channel);
      this.fetch_channels();
    },
    async fetch_channels() {
      this.channels = (await this.$http.get("/api/channels")).data;
      if (!this.show_archived) {
        this.channels = this.channels.filter((x) => x.archived === false);
      }
    },
    async create_channel() {
      await this.$http.post("/api/channels");
      this.fetch_channels();
    },
  },
};
</script>
