<template>
  <header class="wott-container" :class="{ header: true, 'navbar-dark': true }">
    <div class="header__logo">
      <a href='/'>
        <b-img src="../assets/logo_header.png" alt="WOTT" />
      </a>
      <div class="header__title">
        <div class="header__title-short" v-if="false">Tellysim</div>
        <div class="header__title-full" v-if="false"></div>
      </div>
    </div>

    <b-navbar-toggle v-b-toggle.menu target="" @click.prevent="toggleMenu" />

    <div :class="{ collapse: true, show: menuState }">
      <div class="header__form p-1">
        <programming-input />
      </div>

      <div class="header__controls">
        <notifications-button v-if='user.authenticated'/>

        <b-dropdown id="dropdown-1" :text="currentChannel ? currentChannelName : ''"
          class="m-md-2">
          <b-dropdown-item
            v-for="{ text, value } in channel_options"
            :key="`channel_id_${value}`"
            @click.prevent="setCurrentChannel(value)"
            :active="currentChannel && currentChannelId === value"
          >
            {{ text }}
          </b-dropdown-item>
          <template v-if="user.authenticated">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click.prevent="manageUserChannelsModal = true">
              My Channels
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="aboutCreateChannelModal = true">
              New Channel
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="bansModal = true">
              My Bans
            </b-dropdown-item>
          </template>
        </b-dropdown>

        <b-button class="header__about-btn" @click="aboutChannelModal = true"
          >About</b-button
        >

        <template v-if="user.authenticated">
          <div class="header__controls__profile">
            <b-dropdown right class="avatar-dropdown" no-caret>
              <template #button-content>
                <b-avatar
                  :src="user.picture"
                  variant=""
                  class="border border-primary"
                ></b-avatar>
              </template>
              <b-dropdown-item @click.prevent="$emit('openProfile')"
                >Profile
              </b-dropdown-item>
              <b-dropdown-item
                v-if="user.roles.indexOf('admin') !== -1"
                @click.prevent="adminModal = true"
              >
                Admin
              </b-dropdown-item>
            </b-dropdown>
            <b-button @click.prevent="signout_clicked">Sign Out</b-button>
          </div>
        </template>

        <template v-else>
          <b-button
            class="px-3 m-0 ml-3"
            variant="outline-warning"
            @click="show_signup = true"
          >
            Sign Up
          </b-button>
        </template>
      </div>
    </div>

    <about-project-modal v-model="aboutModal" v-on:done="aboutModal = false" />
    <admin v-model="adminModal" v-on:done="adminModal = false" />

    <sign-in
      v-model="show_signin"
      v-on:signup="change_to_signup"
      v-on:done="show_signin = false"
    />

    <sign-up
      v-model="show_signup"
      v-on:signin="change_to_signin"
      v-on:done="show_signup = false"
    />

    <user-create-channel-modal
      v-model="createChannelModal"
      v-on:done="createChannelModal = false"
    />

    <about-user-create-channel-modal
      v-model="aboutCreateChannelModal"
      v-on:done="user_create_channerl"
    />

    <manage-user-channels-modal
      v-model="manageUserChannelsModal"
      v-on:done="manageUserChannelsModal = false"
    />

    <bans-modal v-model="bansModal" v-on:done="bansModal = false" />

    <about-channel-modal
      v-model="aboutChannelModal"
      :channel='currentChannel'
      v-on:done="aboutChannelModal = false"
    />
  </header>
</template>

<script>
import AboutProjectModal from "@/components/Modals/AboutProjectModal.vue";
import Admin from "@/components/Admin.vue";
import ProgrammingInput from "@/components/ProgrammingInput.vue";

import SignIn from "@/components/SignIn.vue";
import SignUp from "@/components/SignUp.vue";

import UserCreateChannelModal from "../components/Modals/UserCreateChannelModal.vue";
import AboutUserCreateChannelModal from "@/components/Modals/AboutUserCreateChannelModal";
import ManageUserChannelsModal from "../components/Modals/ManageUserChannelsModal/Index.vue";

import BansModal from "../components/Modals/BansModal.vue";
import AboutChannelModal from "@/components/Modals/AboutChannelModal";
import aboutChannelModal from "@/components/Modals/AboutChannelModal";

import NotificationsButton from '@/components/NotificationsButton.vue'

export default {
  data: () => ({
    aboutModal: false,
    adminModal: false,
    menuState: false,
    show_signin: false,
    show_signup: false,
    createChannelModal: false,
    aboutCreateChannelModal: false,
    manageUserChannelsModal: false,
    bansModal: false,
    aboutChannelModal: false,
  }),
  components: {
    AboutChannelModal,
    AboutProjectModal,
    Admin,
    ProgrammingInput,
    SignIn,
    SignUp,
    UserCreateChannelModal,
    AboutUserCreateChannelModal,
    ManageUserChannelsModal,
    BansModal,
    aboutChannelModal,
    NotificationsButton
  },
  mounted() {
    this._keyListener = function(e) {
      if (document.activeElement.tagName === 'BODY') {
        if (e.key === 'a') {
          this.adminModal = true
          e.preventDefault()
        }
      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  },
  methods: {
    toggleMenu() {
      this.menuState = !this.menuState;
    },
    change_to_signup() {
      this.show_signin = false;
      this.show_signup = true;
    },
    change_to_signin() {
      this.show_signup = false;
      this.show_signin = true;
    },
    user_create_channerl() {
      this.createChannelModal = true;
      this.aboutCreateChannelModal = false;
    },
    signout_clicked: function () {
      this.$store.dispatch("user/signout");
    },
    setCurrentChannel(val) {
      let channel = this.$store.state.channels.channels.find(
        (x) => x.id === val
      );
      this.$store.dispatch("channels/set_current_channel", channel);
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    channel_options() {
      return this.$store.state.channels.channels.map((x) => {
        return { text: x.name, value: x.id };
      });
    },
    currentChannel() {
      return this.$store.getters["channels/getCurrentChannel"];
    },
    currentChannelId() {
      return this.currentChannel.id;
    },
    currentChannelName() {
      return this.currentChannel.name;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 47px 65px;
  gap: 50px;

  .navbar-toggler {
    display: none;
  }

  .collapse {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 45px;
  }

  &__logo {
    display: flex;
    align-items: center;

    img {
      width: 90px;
      height: auto;
      margin-right: 14px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;

    &-short {
      font-weight: 700;
      font-size: 24px;
    }

    &-full {
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.56);
      white-space: nowrap;
    }
  }

  &__form {
    max-width: 1000px;
    width: 100%;
    height: 44px;
    background-color: #606060;
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 20px;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header__controls__profile .btn {
      background-color: transparent;

      &:active,
      &:focus {
        background-color: transparent;
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;
      }

      &:hover {
        color: white !important;
      }
    }

    button,
    .btn {
      white-space: nowrap;

      &.header__about-btn {
        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
          border: 1px solid transparent;
          outline: none;
          box-shadow: none;
        }
      }

      &.btn-outline-primary:hover {
        color: var(--yellow);
      }

      &.btn-secondary:hover {
        color: var(--yellow);
        border-color: transparent;
      }
    }

    &__profile {
      display: flex;

      button {
        white-space: nowrap;
        color: var(--yellow);
        padding: 0;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    gap: 30px;

    .navbar-toggler {
      display: block;
    }

    .collapse {
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      overflow: hidden;

      width: 100%;
      height: 0;
      transition: height 0.4s;

      &.show {
        gap: 12px;
        height: 110px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 24px 15px;
    gap: 20px;
  }
}
</style>
