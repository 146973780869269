<template>
  <b-modal v-model="active" hide-footer size="lg">
    <template #modal-header>
      <h2><b-icon icon="gear-fill"></b-icon> Settings</h2>

      <div class="buttons-group">
        <b-button
          :disabled="loading"
          class="yellow-button"
          @click.prevent="sendForm"
        >
          <template v-if="loading">
            Saving...
            <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
          </template>
          <template v-else>
            Confirm
            <b-icon icon="check"></b-icon>
          </template>
        </b-button>
        <a href="#" @click.prevent="$emit('done')" class="close-button">
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>
    <b-form>
      <b-form-group>
        <label for="current-password">Current Password</label>
        <b-form-input
          id="current-password"
          type="password"
          autocomplete="false"
          v-model="form.old_password"
        />
      </b-form-group>

      <b-form-group>
        <label for="new-password">New Password</label>
        <b-form-input
          id="new-password"
          type="password"
          v-model="form.password"
        />
      </b-form-group>

      <div v-if="hasErrors" class="validation-messages-wrapper">
        <span v-for="(error, indx) in errors" :key="`validation_error_${indx}`">
          <img src="/clarity_error-solid.svg" alt="" /> {{ error }}
        </span>
      </div>

      <b-form-group>
        <label for="re-enter-new-password">Re-enter New Password</label>
        <b-form-input
          id="re-enter-new-password"
          type="password"
          v-model="form.confirm_password"
        />
      </b-form-group>

      <b-button type="submit" style="display: none">Submit</b-button>
    </b-form>
  </b-modal>
</template>
<script>
export default {
  name: "SettingsModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        old_password: null,
        password: null,
        confirm_password: null,
      },
      errors: [],
    };
  },
  methods: {
    sendForm() {
      let valid = this.validation();

      if (!valid) return;

      this.loading = true;

      this.$http.post("/api/update-profile", this.form).then(() => {
        this.$store.dispatch("user/get_user").then(() => {
          this.loading = false;
          this.$store.commit("push_alert", {
            show: true,
            type: "success",
            message: "Changes saved",
          });

          this.$emit("done");
        });
      });
    },
    validation() {
      this.errors = [];

      if (this.form.password.length < 8) {
        this.errors.push(
          "Password should be atleast 8 characters with letters, symbols, characters and numbers included"
        );
      }

      if (this.form.password !== this.form.confirm_password) {
        this.errors.push("New Password shoul match Re-entered New Password");
      }

      return !this.errors.length;
    },
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    hasErrors() {
      return this.errors.length > 0;
    },
  },
};
</script>
<style scoped lang="scss">
.yellow-button {
  svg {
    margin-left: 5px;
  }
}
</style>
