<template>
  <div class="d-flex flex-column">
    <div
      class="video-wrapper"
      v-if="
        playback &&
        playback.user_video.video.youtube_id &&
        !$store.state.player.disabled
      "
    >
      <youtube
        :video-id="playback.user_video.video.youtube_id"
        ref="youtube"
        class="yt-video-player"
        @playing="playing"
        @ready="youtube_ready"
        :player-vars="player_vars"
        :fitParent="true"
        :resize="true"
        @ended="ended"
        @paused="paused"
        @buffering="buffering"
        @cued="cued"
        @error="error"
      ></youtube>
    </div>
    <b-aspect
      v-else
      :aspect="16 / 9"
      class="mx-auto position-relative"
      style="background: black; width: 100%"
    >
      <b-img
        src="@/assets/wottlogo.svg"
        height="60"
        width="60"
        class="position-absolute"
        style="top: calc(50% - 30px); left: calc(50% - 30px)"
      />
    </b-aspect>
    <template v-if="is_ready">
      <div class="video-progress">
        <span class="bg-progress"></span>
        <span
          class="front-progress"
          :style="{ width: videoProgress + '%' }"
        ></span>
        <span
          class="current-position"
          :style="{ left: videoProgress + '%' }"
        ></span>
      </div>
      <div class="video-controls">
        <div class="controls">
          <div class="timer">
            {{ progress }}
          </div>

          <div class="volume">
            <b-icon
              :icon="volume_icon"
              class="ml-auto h2"
              @click="mute_toggled"
            />
            <span>
              <b-input
                type="range"
                min="0"
                max="100"
                :value="volume"
                @input="volume_changed"
                :style="cssVars"
              />
            </span>
            <div>{{ volume }}%</div>
          </div>

          <template v-if="showWottProgramming">
            <wott-programming></wott-programming>
          </template>
        </div>
        <div class="author">
          <b-avatar class='border border-primary' :src="playback.user_video.user.picture" alt="" />
          <div class="info">
            <span class="name">{{ playback.user_video.user.name }} <span>#9</span></span>
            <span class="rating">{{ playback.user_video.user.ratings }} ratings</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import WottProgramming from "../WottProgramming";
const timeConverter = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export default {
  components: {
    WottProgramming,
  },
  data: () => {
    return {
      volume: 0,
      is_playing: false,
      is_ready: false,
      player_vars: {
        autoplay: 1,
        controls: 0,
        disablekb: 1,
      },
      muted: true,
      duration: 0,
      currentTime: 0,
      now: null,
    };
  },
  created() {
    this.get_now();
    setInterval(this.get_now, 1000);
    //this.$store.dispatch('player/fetch_player_state')
  },
  beforeDestroy() {
    clearInterval(this.get_now);
  },
  computed: {
    progress() {
      if (!this.playback) {
        return "-:-";
      }

      return `${timeConverter(this.currentTime)} / ${timeConverter(
        this.duration
      )}`;
    },
    videoProgress() {
      if (!this.playback) {
        0;
      }

      return (this.currentTime / this.duration) * 100;
    },
    cssVars() {
      return {
        "--volume-value": `${this.volume}%`,
      };
    },
    playback() {
      return this.$store.getters["player/playback"];
    },
    player() {
      return this.$refs.youtube.player;
    },
    volume_icon() {
      if (this.muted) return "volume-mute";
      if (this.volume == 0) return "volume-off";
      if (this.volume < 50) return "volume-down";
      return "volume-up";
    },
    user() {
      return this.$store.getters["user/user"];
    },
    showWottProgramming() {
      if (this.user.authenticated && this.is_ready && this.playback) {
        return true;
      }

      return false;
    },
  },
  watch: {
    playback: {
      handler(val) {
        //if (this.is_ready && val) {
        //  this.seek_and_play();
        //}
      },
      deep: true,
    },
  },
  methods: {
    get_now() {
      this.now = new Date();
    },
    submit_view() {
      this.$http.post("/api/views", { playback: this.playback.id });
    },
    //seek_and_play() {
    //  this.seek()
    //  this.player.playVideo();
    //},
    //seek() {
    //  let d = this.getDeltaSeconds()
    //   console.log("goto seconds", d);
    //  if (d > 1) {
    //    this.currentTime = d
    //    this.player.seekTo(d, true);
    //  }
    //},
    getDeltaSeconds() {
      let t1 = this.$moment(this.playback.date_started);
      let t2 = this.$moment();
      let d = t2.diff(t1, "seconds");
      return d
    },
    async mute_toggled() {
      if (await this.player.isMuted()) {
        this.player.unMute()
        this.muted = false
      } else {
        this.player.mute()
        this.muted = true
      }
    },
    volume_changed(val) {
      this.volume = val;
      this.player.setVolume(val);
    },
    async playing() {
      this.is_playing = true;

      let currentTime = await this.player.getCurrentTime()
      let deltaSeconds = this.getDeltaSeconds()

      if (Math.abs(currentTime - deltaSeconds) > 1) {
        this.currentTime = deltaSeconds
        this.player.seekTo(deltaSeconds, true)
      }

      this.player.getDuration().then((data) => {
        this.duration = data;
      });

      setInterval(() => {
        this.player.getCurrentTime().then((data) => {
          this.currentTime = data;
        });
      }, 1000);

      if (this.user.authenticated) {
        this.submit_view();
      }
    },
    async youtube_ready() {
      this.is_ready = true;
      this.volume_changed(0);
      this.muted = await this.player.isMuted()
      if (this.playback.user_video.video.youtube_id) {
        //this.seek_and_play();
        this.player.playVideo()
      }
    },
    ended() {
    },
    paused() {
      this.player.playVideo();
    },
    buffering() {},
    cued() {},
    error() {},
  },
};
</script>

<style scoped lang="scss">
.video-wrapper iframe {
  width: 100%;
}
.video-progress {
  display: flex;
  margin: 20px 0;
  position: relative;

  .bg-progress {
    width: 100%;
    height: 4px;
    border-radius: 24px;
    background-color: #ffffff;
    opacity: 0.2;
  }

  .front-progress {
    height: 4px;
    border-radius: 24px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
  }

  .current-position {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    border-radius: 50%;
    background-color: var(--warning);
    transform: translate(-8px, -6px);
  }
}

.video-controls {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .controls {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    .timer {
      min-width: 110px;
      display: flex;
      justify-content: center;
    }

    .volume {
      display: flex;
      align-items: center;
      gap: 9px;

      > svg {
        margin: 0;
      }

      span {
        display: flex;
        align-items: center;
      }

      input {
        max-width: 80px;
        position: relative;

        &:before {
          content: " ";
          width: var(--volume-value);
          background-color: #ffffff;
          position: absolute;
          left: 0;
          height: 5px;
          z-index: -1;
          border: 1px solid #ffffff;
          top: 50%;
          border-radius: 16px;
          transform: translateY(-50%);
        }
      }

      input[type="range"]::-webkit-slider-runnable-track {
        background: #ffffff33;
        height: 5px;
      }

      input[type="range"]::-moz-range-track {
        background: #ffffff33;
        height: 5px;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 15px;
        width: 15px;
        background: #ffffff;
        margin-top: -5px;
        border-radius: 50%;
      }

      div {
        min-width: 41px;
      }
    }

    button {
      margin: 0;
      padding: 0;
    }
  }

  .author {
    display: flex;
    gap: 13px;
    align-items: center;
    min-width: 200px;

    img {
      border: 1px solid var(--warning);
      border-radius: 50%;
      max-height: 48px;
    }

    .info {
      display: flex;
      flex-direction: column;
      font-size: 16px;

      .name {
        font-weight: 600;

        span {
          color: var(--warning);
        }
      }
    }
  }
}
</style>
