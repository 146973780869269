<template>
  <b-modal v-model="active" hide-footer size="lg">
    <template #modal-header>
      <h2>About Tellysim</h2>

      <div class="buttons-group">
        <a href="#" @click.prevent="$emit('done')" class="close-button">
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>
    <h2>About</h2>
    <p class="">
      Tellysim is a competitive video sharing platform. Once you have an account
      you have the ability to earn
      <span class="text-danger">R</span>atings which act as currency and as
      points. You can earn ratings by submitting quality entertainment to the
      video player via a YouTube link. The link you submit is selected to be
      played at random. If the viewers like your video they will award you with
      ratings, if they hate it they will vote to cancel. If more than 70% of
      viewers vote to cancel, the video is skipped. To guarantee that your link
      is played sooner, you can try to buy a time slot from the auction using
      your <span class="text-danger">R</span>atings. If you win the auction, the
      link you submitted will be the next video that is selected to be played
      and the viewers cannot skip it for 3 minutes.
    </p>
    <h2>Rules</h2>
    <ol>
      <li>No hate speech.</li>
      <li>Don't submit videos that contain death/gore/violence.</li>
      <li>Try to be nice in the chat.</li>
    </ol>
    <h2>Tips</h2>
    <ol>
      <li>
        Don't post videos that are age or license restricted. They won't work.
      </li>
      <li>
        Join the descord!
        <b-link href="https://discord.gg/f5ABq85qqp"
          >https://discord.gg/f5ABq85qqp</b-link
        >
      </li>
      <li>
        Buy Ratings! Doing this not only helps the development of this site, it
        also rewards you with POWER and STATUS (in a small internet community).
      </li>
      <li>Tell your friends! More viewers = more Ratings.</li>
    </ol>
  </b-modal>
</template>
<script>
export default {
  name: "AboutProjectModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style scoped lang="scss"></style>
