<template>
  <b-modal
    v-model="active"
    hide-footer
    size="lg"
    v-on:show="loadChannelCategories"
  >
    <template #modal-header>
      <h2>Update Channel</h2>

      <div class="buttons-group">
        <b-button
          :disabled="loading"
          class="yellow-button"
          @click.prevent="updateChannel"
        >
          <template v-if="loading">
            Saving...
            <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
          </template>
          <template v-else>
            Update
            <b-icon icon="check"></b-icon>
          </template>
        </b-button>
        <a href="#" @click.prevent="$emit('done')" class="close-button">
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>
    <b-form class="create-channel">
      <b-form-group>
        <label for="description">Channel picture</label>
        <label class="create-channel__picture">
          <b-form-file
            accept="image/jpeg, image/png, image/gif"
            @change="onPictureChange"
            @input="attachPictureToChannel"
            v-model="filePicture"
          ></b-form-file>
          <img v-if="!prevPicture" :src="form.picture" alt="" />
          <img v-if="prevPicture" :src="prevPicture" alt="" />
        </label>
      </b-form-group>
      <b-form-group>
        <label for="name">Channel Name</label>
        <b-form-input
          id="name"
          type="text"
          autocomplete="false"
          v-model="form.name"
        />
      </b-form-group>
      <b-form-group>
        <label for="maximum_video_length">Maximum video length</label>
        <b-form-input
          id="maximum_video_length"
          type="text"
          autocomplete="false"
          v-model="form.maximum_video_length"
        />
      </b-form-group>
      <b-form-group>
        <label for="timeslot_duration">Timeslot duration</label>
        <b-form-input
          id="timeslot_duration"
          type="text"
          autocomplete="false"
          v-model="form.timeslot_duration"
        />
      </b-form-group>
      <b-form-group>
        <label for="auction_duration">Auction duration</label>
        <b-form-input
          id="auction_duration"
          type="text"
          autocomplete="false"
          v-model="form.auction_duration"
        />
      </b-form-group>
      <b-form-group>
        <label for="cancel_pct">Cancel pct</label>
        <b-form-input
          id="cancel_pct"
          type="text"
          autocomplete="false"
          v-model="form.cancel_pct"
        />
      </b-form-group>
      <b-form-group>
        <label for="uncancellable_time_normal">Uncancellable time normal</label>
        <b-form-input
          id="uncancellable_time_normal"
          type="text"
          autocomplete="false"
          v-model="form.uncancellable_time_normal"
        />
      </b-form-group>
      <b-form-group>
        <label for="uncancellable_time_timeslot"
          >Uncancelable time timeslot</label
        >
        <b-form-input
          id="uncancellable_time_timeslot"
          type="text"
          autocomplete="false"
          v-model="form.uncancellable_time_timeslot"
        />
      </b-form-group>
      <b-form-group>
        <label for="minimum_ratings">Minimum ratings</label>
        <b-form-input
          id="minimum_ratings"
          type="text"
          autocomplete="false"
          v-model="form.minimum_ratings"
        />
      </b-form-group>
      <b-form-group>
        <label for="description">Description</label>
        <b-form-textarea
          id="description"
          v-model="form.description"
          rows="4"
          maxlength="200"
        />
      </b-form-group>
      <b-form-group>
        <label for="categories">Theme</label>
        <b-form-select
          id="categories"
          v-model="selectedCategory"
          :options="categories"
          :text-field="'title'"
          :value-field="'id'"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>
<script>
export default {
  name: "EditChannelModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      selectedCategory: null,
      filePicture: null,
      prevPicture: null,
    };
  },
  created() {
    this.form = Object.assign({}, this.formData);
  },
  methods: {
    async updateChannel() {
      this.loading = true;

      await this.$http.put("/api/channels", this.form);
      await this.attachCategoriesToChannel();
      await this.$store.dispatch("channels/fetch_channels");

      this.loading = false;
      this.$emit("done");
    },
    async loadChannelCategories() {
      const categories = await this.$http.get(
        `/api/channel-categories?channel_id=${this.form.id}`
      );
      this.selectedCategory = categories.data.map((cat) => cat.id)[0];
    },
    async attachCategoriesToChannel() {
      const response = await this.$http.put(`/api/channel-categories`, {
        channel_id: this.form.id,
        category_ids: this.selectedCategory ? [this.selectedCategory] : [],
      });

      return response.data;
    },
    async attachPictureToChannel() {
      let formDataPicture = new FormData();

      formDataPicture.append("file", this.filePicture);
      formDataPicture.append(
        "data",
        JSON.stringify({ channel_id: this.form.id })
      );

      const response = await this.$http
        .post(`/api/update-channel-picture`, formDataPicture, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          this.$store.dispatch("user/get_user").then(() => {
            this.loading = false;
            this.$store.commit("push_alert", {
              show: true,
              type: "success",
              message: "Changes saved",
            });

            this.$emit("done");
            this.prevPicture = null;
          });
        });

      return response.data;
    },
    onPictureChange(e) {
      const file = e.target.files[0];
      this.prevPicture = URL.createObjectURL(file);
    },
  },

  computed: {
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    categories: {
      get() {
        return this.$store.getters["categories/categories"];
      },
      set: () => null,
    },
  },

  watch: {
    formData() {
      this.form = Object.assign({}, this.formData);
    },
  },
};
</script>
<style scoped lang="scss">
.yellow-button {
  svg {
    margin-left: 5px;
  }
}

.create-channel__picture {
  position: relative;
  cursor: pointer;
  width: 200px;
  height: 200px;
  padding: 0;
  border-radius: 50%;

  &::before,
  &::after {
    opacity: 0;
    transition: 0.3s;
  }

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background: #000000;
      border-radius: 50%;
      opacity: 0.73;
      z-index: 2;
      transition: 0.3s;
    }

    &::after {
      content: url("../../assets/icn-photo.svg");
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.62;
      z-index: 3;
      transition: 0.3s;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  .custom-file {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
  }
}
</style>
