<template>
  <b-modal v-model="show_modal" size="lg">
    <template #modal-header>
      <h2>My Profile</h2>
      <div>
        <b-button
          @click.prevent="$emit('openBuyRatingsModal')"
          variant="primary"
        >
          Buy Ratings
        </b-button>
        <b-button @click.prevent="openSettings">
          <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
          Settings
        </b-button>
        <b-button @click.prevent="openEditProfile">
          <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
          Edit Profile
        </b-button>
        <b-button class="close" @click="$emit('done')">
          <b-icon-x size="lg" />
        </b-button>
      </div>
    </template>
    <div class="d-flex align-items-start">
      <b-avatar :src="user.picture" class="profile-avatar m-3" size="140" />
      <div class="mt-4 text-left">
        <h3>
          {{ user.name }}
        </h3>
        <p class="text-primary">{{ user.ratings }} ratings</p>
        <p v-if="user.location">
          {{ user.location }}
        </p>
        <p v-if="user.about_me">
          {{ user.about_me }}
        </p>
        <p v-else>
          <i>Add your information in Edit Profile.</i>
        </p>
      </div>
    </div>
    <div class="d-none">
      <h2>Top Videos</h2>
    </div>
    <template #modal-footer>
      <b-button class="w-100 text-danger p-3" @click="delete_account_clicked">
        <b-icon-trash class="mr-2" />Delete Account
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// import Rating from "@/components/Rating.vue";

export default {
  props: ["value"],
  components: {
    // Rating
  },
  created() {},
  computed: {
    show_modal: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        return this.value;
      },
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    buy_ratings_triggered() {},
    delete_account_clicked() {},
    openSettings() {
      this.$emit("openSettings");
    },
    openEditProfile() {
      this.$emit("done");
      this.$emit("openEditProfile");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-header {
  .btn {
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;

    &.btn-primary {
      color: #2a2727;
      border-radius: 5px;
    }

    &.btn-secondary {
      color: #fed530;

      svg {
        margin-right: 10px;
      }
    }

    &.close {
      width: 42px;
      height: 42px;
      padding: 0;
      margin: 0;
      background: rgba(41, 41, 41, 0.9);
      border-radius: 5px;
      color: #ffffff;
      svg {
        margin: 0;
      }
    }
  }
}
</style>
