<template>
  <div class="voting-controls">
    <!-- <b-input-group class="justify-content-center mb-2">
      <b-input-group-prepend v-if="is_on_air">
        <b-input-group-text class="text-danger bg-secondary">
          ON AIR
        </b-input-group-text>
      </b-input-group-prepend>
      <b-input
        placeholder="Paste Youtube Link"
        @input="
          link_submitted = false;
          link_invalid = false;
        "
        v-model="video_url"
        class="flex-grow-0"
        style="flex-basis: 50%"
      ></b-input>
      <b-input-group-append
        v-if="link_submitted || link_invalid || link_loading"
      >
        <b-input-group-text>
          <b-icon-check v-if="link_submitted" color="green" />
          <b-icon-x v-else-if="link_invalid" color="red" />
          <b-spinner small v-else variant="primary" />
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group> -->
    <div v-if="cancel_blocked > 0" class="timer">
      <b-img src="../assets/clock.png" />
      <span>{{ Math.round(cancel_blocked) }}</span>
    </div>
    <div class="award">
      <b-link href="#" @click.prevent="vote(true)">
        Award ratings
        <img src="@/assets/ratings-symbol.svg" alt="" />
      </b-link>
      <span>
        +{{ votes.up.n }}
        <img src="@/assets/ratings-symbol.svg" alt="" />
      </span>
    </div>
    <div class="cancel">
      <b-link
        href="#"
        @click.prevent="vote(false)"
        :disabled="cancel_blocked > 0"
      >
        Cancel
      </b-link>
      <span>
        <img src="@/assets/red-stop.svg" alt="" />
        {{ format_pct(votes.down.pct) }}%
      </span>
    </div>
  </div>
</template>

<script>
function parseTimestamp(timestampStr) {
  return new Date(
    new Date(timestampStr).getTime() -
      new Date(timestampStr).getTimezoneOffset() * 60 * 1000
  );
}

export default {
  data: () => {
    return {
      video_url: "",
      link_submitted: false,
      link_invalid: false,
      link_loading: false,
      live_ratings: {},

      now: null,
    };
  },
  created() {
    this.get_now();
    setInterval(this.get_now, 1000);
  },
  beforeDestroy() {
    clearInterval(this.get_now);
  },
  computed: {
    cancel_blocked() {
      let playback = this.$store.getters["player/playback"];
      if (!playback) {
        return false;
      }
      let started = parseTimestamp(playback.date_started);
      let delta = (this.now - started) / 1000;
      let is_timeslot = playback.user_video.priority == 2;
      let blocked_seconds = is_timeslot ? 120 : 15;
      //let cancellable = delta > blocked_seconds
      let remaining = blocked_seconds - delta;
      return remaining;
    },
    votes() {
      return this.$store.getters["player/votes"];
    },
    is_on_air() {
      return this.playback && this.user.id === this.playback.user_video.user.id;
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    get_now() {
      this.now = new Date();
    },
    format_pct(v) {
      return Math.round(v * 100);
    },
    vote(v) {
      this.$store.dispatch("player/vote", v);
    },
  },
};
</script>

<style scoped lang="scss">
.voting-controls {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  a {
    padding: 10px 17px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: 0.3s;

    &:hover {
      text-decoration: none;
    }

    &:active {
      outline: 1px solid var(--yellow);
    }
  }

  span {
    font-size: 16px;
    font-weight: 600;
  }

  .timer {
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      max-height: 24px;
    }

    span {
      min-width: 22px;
    }
  }

  .award,
  .cancel {
    display: flex;
    align-items: center;
    gap: 9px;
  }

  .award {
    > a {
      background-color: rgba(254, 213, 48, 0.09);
      color: var(--warning);
      white-space: nowrap;
      padding-right: 25px;
    }

    img {
      max-height: 13px;
    }

    span {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }

  .cancel {
    > a {
      background-color: rgba(235, 87, 87, 0.09);
      color: rgb(235, 87, 87);

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }
  }
}
</style>
