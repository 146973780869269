<template>
  <div>
    <b-table :items="timeslots" :fields="fields">
      <template #cell(date_closes)="data">
        {{ $moment(data.item.date_closes).format("MM-DD HH:mm:ss") }}
      </template>
      <template #cell(date_started)="data">
        {{ $moment(data.item.date_started).format("MM-DD HH:mm:ss") }}
      </template>
      <template #cell(date_ends)="data">
        {{ $moment(data.item.date_ends).format("MM-DD HH:mm:ss") }}
      </template>
      <template #cell(date_ended)="data">
        {{ $moment(data.item.date_ended).format("MM-DD HH:mm:ss") }}
      </template>
      <template #cell(date_closed)="data">
        {{ $moment(data.item.date_closed).format("MM-DD HH:mm:ss") }}
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      fields: [
        "id",
        "date_started",
        "date_ends",
        "date_closes",
        "date_ended",
        "date_closed",
        "max_bid",
        "winner_id",
        "video_id",
      ],
    };
  },
  computed: {
    timeslots() {
      return this.$store.state.data.timeslots;
    },
  },
  async created() {
    this.$store.state.data.timeslots = (await axios.get('/api/get-timeslots')).data
  },
  methods: {},
};
</script>
