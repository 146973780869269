<template>
  <div>
    <b-badge v-for="{ id, title } in cats" :key="`cat_${id}`">{{
      title
    }}</b-badge>
  </div>
</template>

<script>
export default {
  name: "CategoriesCell",
  props: ["categories"],
  computed: {
    cats() {
      return this.$store.getters["categories/categories"].filter((_cat) =>
        this.categories.includes(_cat.id)
      );
    },
  },
};
</script>

<style scoped></style>
