<template>
  <b-modal
    v-model="active"
    hide-footer
    size="xl"
    content-class="scrollable-x-body"
  >
    <template #modal-header>
      <h2>My Channels</h2>

      <div class="buttons-group">
        <a href="#" @click.prevent="$emit('done')" class="close-button">
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>
    <span v-if="!currentUserChannels.length"> You don't have channels.</span>
    <div class="card-list">
      <b-card
        v-for="channel in currentUserChannels"
        :key="channel.id"
        class="my-2 d-flex"
      >
        <b-img :src="channel.picture" alt="Image" class="rounded-0"></b-img>
        <div class="d-flex flex-column">
          <div>{{ channel.name }}</div>
          <div>{{ channel.description }}</div>
          <div class="d-flex">
            <div class="action-buttons-group">
              <b-button @click.prevent="editChannel(channel)">
                <b-icon icon="pencil-square"></b-icon>
                Edit
              </b-button>
              <b-button @click.prevent="triggerDeleteChannelModal(channel)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <user-edit-channel-modal
      v-model="editModal"
      :form-data="editingChannel"
      v-on:done="editModal = false"
    />
  </b-modal>
</template>

<script>
import axios from "axios";
import UserEditChannelModal from "../UserEditChannelModal.vue";
import CategoriesCell from "./CategoriesCell.vue";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UserEditChannelModal,
    CategoriesCell,
  },
  data: () => ({
    headers: [
      { key: "name", label: "Name" },
      { key: "maximum_video_length", label: "Maximum video length" },
      { key: "timeslot_duration", label: "Timeslot duration" },
      { key: "auction_duration", label: "Auction duration" },
      { key: "cancel_pct", label: "Cancel PCT" },
      { key: "uncancellable_time_normal", label: "Uncancellable time normal" },
      {
        key: "uncancellable_time_timeslot",
        label: "Uncancellable time timeslot",
      },
      { key: "minimum_ratings", label: "Minimum ratings" },
      { key: "description", label: "Description" },
      { key: "category_ids", label: "Categories" },
      { key: "actions", label: "Actions" },
    ],
    deleting: false,
    editModal: false,
    editingChannel: {},
  }),
  methods: {
    async triggerDeleteChannelModal(item) {
      let v = await this.$bvModal.msgBoxConfirm(
        `Delete channel ${item.name}?`,
        {
          size: "md",
          buttonSize: "md",
        }
      );

      if (v) {
        let url = "/api/channels";
        let data = { id: item.id };
        let headers = { "Content-Type": "application/json" };
        await axios.request({ url, data, headers, method: "delete" });

        this.$store.dispatch("channels/fetch_channels");
      }
    },
    editChannel(item) {
      console.log(item);
      this.editingChannel = Object.assign({}, item);
      this.editModal = true;
    },
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    currentUserChannels() {
      return this.$store.getters["channels/getCurrentUserChannels"];
    },
  },
};
</script>
<style scoped lang="scss">
.action-buttons-group {
  display: flex;
  gap: 5px;
}

.card-body {
  display: flex;
  gap: 10px;

	img {
		width: 150px; 
		height: 150px; 
		object-fit: cover;
	}
}
</style>
