<script>
import { Line } from "vue-chartjs";

export default {
  props: {
    incomingData: {
      type: Array,
      default: () => [],
    },
  },
  extends: Line,
  data() {
    return {
      options: {
        tooltips: { enabled: false },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                suggestedMax: 5,
                stepSize: 1,
                display: false,
              },
              gridLines: {
                borderDash: [3, 5],
                color: "#E2E7E737",
                drawBorder: false,
              },
              scaleLabel: {
                display: false,
                labelString: "Viewers",
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: false,
                labelString: "Time",
              },
              gridLines: {
                display: false,
              },
              ticks: {
                max: 23,
                min: 0,
                stepSize: 1,
                source: "labels",
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    render() {
      this.renderChart(this.chartData, this.options);
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.incomingData.map((item) => item.x),
        datasets: [
          {
            data: this.incomingData,
            fill: true,
            borderColor: "#FED530",
            borderWidth: 3,
            lineTension: 0,
            pointRadius: 0,
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.chartjs-render-monitor {
  width: 100% !important;
  height: 300px;

  @media screen and (min-width: 1024px) {
    max-width: 607px !important;
  }
}
</style>
