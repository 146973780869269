<template>
  <div class='text-white'>
    <b-table
      :items="items"
      :fields="fields"
      small
      per-page="0"
      @row-clicked="handle_row_clicked"
    >
      <template #cell(timestamp)="data">
        {{ $moment(data.item.timestamp).format("hh:mm:ss") }}
      </template>

      <template #cell(message)="data">
        <template v-if="data.item.name === 'message'">
          {{ data.item.message.name }}
        </template>
      </template>

      <template #row-details="data"
        ><template v-if="data.item.name === 'message'">
          <pre
            >{{ JSON.stringify(data.item.message.data, null, 2) }}
          </pre>
        </template>
        <template v-else>
          {{ data.item.event }}
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: ["timestamp", "name", "message"],
      allOpenRows: [],
    };
  },
  computed: {
    items() {
      return this.$store.state.websocket.websocket_items;
    },
  },
  methods: {
    format_timestamp(t) {
      return this.$moment(t).format("hh:mm:ss");
    },
    handle_row_clicked(item) {
      this.allOpenRows.map((ele) => {
        if (ele.timestamp !== item.timestamp && ele._showDetails) {
          this.$set(ele, "_showDetails", !ele._showDetails);
        }
      });
      this.allOpenRows = [];
      this.$set(item, "_showDetails", !item._showDetails);
      this.allOpenRows.push(item);
    },
  },
};
</script>
