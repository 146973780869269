<template>
  <b-modal
    v-model="active"
    hide-footer
    size="xl"
    content-class="scrollable-x-body"
    scrollable
  >
    <template #modal-header>
      <h2>My Bans</h2>

      <div class="buttons-group">
        <a href="#" @click.prevent="$emit('done')" class="close-button">
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>
    <b-table
      striped
      :items="bannedUsers"
      :fields="fields"
      :busy="loading"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(channel)="data">
        {{ channels.find((ch) => ch.id === data.item.channel_id).name }}
      </template>
      <template #cell(user)="data">
        {{ data.item.user.name }}
      </template>
      <template #cell(banned_at)="data">
        {{ data.item.banned_at | formatDate }}
      </template>
      <template #cell(action)="data">
        <b-btn @click.prevent="restoreViewer(data.item.id)">
          <b-icon icon="trash"></b-icon>
        </b-btn>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    fields: ["user", "channel", "banned_at", "action"],
    bannedUsers: [],
  }),
  methods: {
    getBannedUsers() {
      if (!this.user.authenticated || this.loading) return;
      this.loading = true;

      axios
        .get(`/api/bans`)
        .then((res) => {
          this.bannedUsers = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async restoreViewer(banId) {
      let url = "/api/bans";
      let data = { ban_id: banId };
      let headers = { "Content-Type": "application/json" };
      await axios.request({ url, data, headers, method: "delete" });

      this.$store.commit("push_alert", {
        show: true,
        type: "success",
        message: "User removed from ban",
      });

      this.getBannedUsers();
    },
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("channels", ["channels"]),
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getBannedUsers();
      }
    },
  },
  filters: {
    formatDate: function (value) {
      let _date = new Date(value);

      return _date.toLocaleString("en-UK");
    },
  },
};
</script>
<style scoped lang="scss"></style>
