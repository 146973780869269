<template>
  <b-modal v-model="active" hide-footer size="lg">
    <template #modal-header>
      <h2>Create Channel</h2>

      <div class="buttons-group">
        <!-- <a href="#" @click.prevent="sendForm" class="yellow-button">
                    Save Changes
                    <b-icon icon="check"></b-icon>
                  </a> -->
        <b-button
          :disabled="loading"
          class="yellow-button"
          @click.prevent="createChannel"
        >
          <template v-if="loading">
            Saving...
            <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
          </template>
          <template v-else>
            Create
            <b-icon icon="check"></b-icon>
          </template>
        </b-button>
        <a href="#" @click.prevent="$emit('done')" class="close-button">
          <b-icon icon="x"></b-icon>
        </a>
      </div>
    </template>
    <b-form class="create-channel">
      <b-form-group>
        <label for="name">Channel Name</label>
        <b-form-input
          id="name"
          type="text"
          autocomplete="false"
          v-model="form.name"
        />
      </b-form-group>
      <b-form-group>
        <label for="maximum_video_length">Maximum video length</label>
        <b-form-input
          id="maximum_video_length"
          type="text"
          autocomplete="false"
          v-model="form.maximum_video_length"
        />
      </b-form-group>
      <b-form-group>
        <label for="timeslot_duration">Timeslot duration</label>
        <b-form-input
          id="timeslot_duration"
          type="text"
          autocomplete="false"
          v-model="form.timeslot_duration"
        />
      </b-form-group>
      <b-form-group>
        <label for="auction_duration">Auction duration</label>
        <b-form-input
          id="auction_duration"
          type="text"
          autocomplete="false"
          v-model="form.auction_duration"
        />
      </b-form-group>
      <b-form-group>
        <label for="cancel_pct">Cancel pct</label>
        <b-form-input
          id="cancel_pct"
          type="text"
          autocomplete="false"
          v-model="form.cancel_pct"
        />
      </b-form-group>
      <b-form-group>
        <label for="uncancellable_time_normal">Uncancellable time normal</label>
        <b-form-input
          id="uncancellable_time_normal"
          type="text"
          autocomplete="false"
          v-model="form.uncancellable_time_normal"
        />
      </b-form-group>
      <b-form-group>
        <label for="uncancellable_time_timeslot"
          >Uncancelable time timeslot</label
        >
        <b-form-input
          id="uncancellable_time_timeslot"
          type="text"
          autocomplete="false"
          v-model="form.uncancellable_time_timeslot"
        />
      </b-form-group>
      <b-form-group>
        <label for="minimum_ratings">Minimum ratings</label>
        <b-form-input
          id="minimum_ratings"
          type="text"
          autocomplete="false"
          v-model="form.minimum_ratings"
        />
      </b-form-group>
      <b-form-group>
        <label for="description">Description</label>
        <b-form-textarea
          id="description"
          v-model="form.description"
          rows="4"
          maxlength="200"
        />
      </b-form-group>
      <b-form-group>
        <label for="categories">Theme</label>
        <b-form-select
          id="categories"
          v-model="selectedCategory"
          :options="categories"
          :text-field="'title'"
          :value-field="'id'"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>
<script>
export default {
  name: "CreateChannelModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultForm: {
        name: "Default",
        maximum_video_length: 10,
        timeslot_duration: 30,
        auction_duration: 25,
        cancel_pct: 0.7,
        uncancellable_time_normal: 15,
        uncancellable_time_timeslot: 120,
        minimum_ratings: 10,
        archived: false,
      },
      form: {
        name: "Default",
        maximum_video_length: 10,
        timeslot_duration: 30,
        auction_duration: 25,
        cancel_pct: 0.7,
        uncancellable_time_normal: 15,
        uncancellable_time_timeslot: 120,
        minimum_ratings: 10,
        archived: false,
      },
      loading: false,
      selectedCategory: null,
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    async createChannel() {
      this.loading = true;
      let createdChannel;
      try {
        try {
          createdChannel = await this.$http.post("/api/channels");
        } catch (e) {
          this.$store.dispatch('push_error', 'Creating channel failed.')
          throw e
        }

        this.form.id = createdChannel.data.id;
        await this.updateChannelInitialInfo();

        try {
          await this.attachCategoriesToChannel(createdChannel.data.id);
        } catch (e) {
          this.$store.dispatch('push_error', 'Channel creating but setting category failed.')
          throw e
        }
      } finally {
        this.loading = false;
      }

      this.form = Object.assign({}, this.defaultForm);

      await this.$store.dispatch("channels/fetch_channels");
      this.$emit("done");
    },
    async updateChannelInitialInfo() {
      const response = await this.$http.put("/api/channels", this.form);
      return response.data;
    },
    async getCategories() {
      const response = await this.$http.get("/api/categories");
      this.categories = response.data;
    },
    async attachCategoriesToChannel(channelId) {
      const response = await this.$http.put(`/api/channel-categories`, {
        channel_id: channelId,
        category_ids: this.selectedCategory ? [this.selectedCategory] : [],
      });

      return response.data;
    },
  },

  computed: {
    active: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    categories: {
      get() {
        return this.$store.getters["categories/categories"];
      },
      set: () => null,
    },
  },
};
</script>
<style scoped lang="scss">
.yellow-button {
  svg {
    margin-left: 5px;
  }
}
</style>
