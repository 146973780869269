<template>
  <div>
    <div>
      <b-button @click="clear_user_videos">Clear user videos</b-button>
    </div>
    <b-table :items="user_videos" :fields="fields">
      <template #cell(user_email)="data">
        {{ data.item.user_id }}
      </template>
      <template #cell(youtube_id)="data">
        {{ data.item.video_id }}
      </template>
      <template #cell(date_submitted)="data">
        {{ $moment(data.item.date_submitted).format("MM-DD HH:mm") }}
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      fields: [
        "id",
        "user_email",
        "youtube_id",
        { key: "date_submitted", label: "Submitted" },
        "priority",
        "played",
        "replaced",
        "expired",
        "cancelled",
      ],
    };
  },
  computed: {
    user_videos() {
      return this.$store.state.data.user_videos;
    },
  },
  async created() {
    this.$store.state.data.user_videos = (await axios.get('/api/user-videos')).data
  },
  methods: {
    async clear_user_videos() {
      await axios.post('/api/clear-user-videos')
    },
  },
};
</script>
